import React from "react";
import "../assets/css/sports.css";

const Sports = () => {
  return (
    <div className="sports-container max-width game-lunch">
     
    </div>
  );
};

export default Sports;
