import React from "react";
function Footer() {
  return (
    <div className="max-width">
      <div className="contanier">
        <div className="fgbfo2v footer-type-0" id="page-footer">
          <div className="s1ggjuy9 footer-crypto-text-wrap">
            <div className="footer-container page-max-width-wrap">
              <div className="h1ux4v4o home-text-wrap">
                <div className="center-box">
                  <div className="crypto-text">
                    <h2>Online Casino</h2>
                    <p>
                      Casinos online have not always been around, but we can
                      safely say that online casinos have been used a lot since
                      they came on the market. And it's not in short demand nor
                      options, and now in 2024, we have 1000s and 1000s to pick
                      from – it's just a matter of what you like and what
                      payment options you would like to see at the casino.
                    </p>
                    <p>
                      Players are always looking for something new, which will
                      help make the gaming experience so much better and more
                      accessible. Allowing the player to focus on the absolute
                      fun of a casino, that's right, the games themselves.
                    </p>
                  </div>
                  <div className="btn-more">
                    <span className="cl-primary">Show more &gt;&gt;</span>
                  </div>
                </div>
              </div>
              <div className="sckkbci helpus-wrap">
                <div className="helpus-inner">
                  <div className="help-us-infos">
                    <h2 className="tit">Help Us Improve Your Experience</h2>
                    <textarea
                      placeholder="Found a bug or have any recommendations? Please leave your message here"
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                    />
                    <div className="send-wrap">
                      <button className="ui-button button-normal s-conic">
                        <div className="button-inner">Leave a Message</div>
                      </button>
                      <div className="message">
                        <p>Now get rewarded for your valuable feedback.</p>
                      </div>
                    </div>
                  </div>
                  <div className="contact">
                    Or Email us:<a href="#" className="cl-primary">
                      feedback@site.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-max-width-wrap footer-infos">
            <div className="f1mx1119 footer-links-wrap">
              <div className="footer-link-wrap">
                <p className="f-t">Casino</p>
                <div className="links">
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Casino Home"
                    target="_self"
                  >
                    <span>Casino Home</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Slots"
                    target="_self"
                  >
                    <span>Slots</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Live Casino"
                    target="_self"
                  >
                    <span>Live Casino</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="New Releases"
                    target="_self"
                  >
                    <span>New Releases</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Recommended"
                    target="_self"
                  >
                    <span>Recommended</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Table Game"
                    target="_self"
                  >
                    <span>Table Game</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="BlackJack"
                    target="_self"
                  >
                    <span>BlackJack</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Roulette"
                    target="_self"
                  >
                    <span>Roulette</span>
                  </a>
                  <a
                    href="/tagname/baccarat"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Baccarat"
                    target="_self"
                  >
                    <span>Baccarat</span>
                  </a>
                </div>
              </div>
              <div className="footer-link-wrap">
                <p className="f-t">Sports</p>
                <div className="links">
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Sports Home"
                    target="_self"
                  >
                    <span>Sports Home</span>
                  </a>
                  <a
                    href="/sports?bt-path=%2Flive"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Live"
                    target="_self"
                  >
                    <span>Live</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Rules"
                    target="_self"
                  >
                    <span>Rules</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Sport Betting Insights"
                    target="_blank"
                  >
                    <span>Sport Betting Insights</span>
                    <svg
                      className="hashbet"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M365.386 83.556c31.44 0 56.926 25.486 56.926 56.926 0 30.897-24.617 56.046-55.307 56.904l-1.62 0.022h-170.777v629.186h629.186v-170.777c0-30.897 24.617-56.046 55.307-56.904l1.62-0.022c30.897 0 56.045 24.617 56.904 55.307l0.022 1.62v179.768c0 57.258-45.887 103.796-102.894 104.848l-1.968 0.017h-647.16c-57.258 0-103.796-45.887-104.848-102.894l-0.017-1.968v-647.16c0-57.258 45.887-103.796 102.894-104.848l1.968-0.017h179.768zM611.108 84.556l1.62 0.013 227.481 4.701c57.907 1.009 104.028 48.774 103.019 106.677l-1.176 62.495-1.198 54.931-1.442 62.539-0.437 21.864c-0.034 1.776-0.066 3.547-0.094 5.315-0.549 31.434-26.475 56.472-57.909 55.926s-56.472-26.475-55.926-57.909l0.308-16.334 2.11-93 0.246-11.744-360.933 330.867c-23.176 21.246-59.187 19.678-80.428-3.495-20.849-22.748-19.728-57.854 2.233-79.234l1.267-1.193 355.396-325.787-134.861-2.783c-30.889-0.638-55.524-25.77-55.748-56.469l0.013-1.62c0.638-30.889 25.77-55.524 56.469-55.748z" />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="footer-link-wrap">
                <p className="f-t">Promo</p>
                <div className="links">
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="VIP Club"
                    target="_self"
                  >
                    <span>VIP Club</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Affiliate"
                    target="_self"
                  >
                    <span>Affiliate</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Promotions"
                    target="_self"
                  >
                    <span>Promotions</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Lottery"
                    target="_self"
                  >
                    <span>Lottery</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Refer a friend"
                    target="_self"
                  >
                    <span>Refer a friend</span>
                  </a>
                </div>
              </div>
              <div className="footer-link-wrap">
                <p className="f-t">Support/Legal</p>
                <div className="links">
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Help center"
                    target="_self"
                  >
                    <span>Help center</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Gamble Aware"
                    target="_self"
                  >
                    <span>Gamble Aware</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Fairness"
                    target="_self"
                  >
                    <span>Fairness</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="FAQ"
                    target="_self"
                  >
                    <span>FAQ</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Privacy Policy"
                    target="_self"
                  >
                    <span>Privacy Policy</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Terms Of Service"
                    target="_self"
                  >
                    <span>Terms Of Service</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Self-exclusion"
                    target="_self"
                  >
                    <span>Self-exclusion</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="AML"
                    target="_self"
                  >
                    <span>AML</span>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Design Resources"
                    target="_blank"
                  >
                    <span>Design Resources</span>
                    <svg
                      className="hashbet"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M365.386 83.556c31.44 0 56.926 25.486 56.926 56.926 0 30.897-24.617 56.046-55.307 56.904l-1.62 0.022h-170.777v629.186h629.186v-170.777c0-30.897 24.617-56.046 55.307-56.904l1.62-0.022c30.897 0 56.045 24.617 56.904 55.307l0.022 1.62v179.768c0 57.258-45.887 103.796-102.894 104.848l-1.968 0.017h-647.16c-57.258 0-103.796-45.887-104.848-102.894l-0.017-1.968v-647.16c0-57.258 45.887-103.796 102.894-104.848l1.968-0.017h179.768zM611.108 84.556l1.62 0.013 227.481 4.701c57.907 1.009 104.028 48.774 103.019 106.677l-1.176 62.495-1.198 54.931-1.442 62.539-0.437 21.864c-0.034 1.776-0.066 3.547-0.094 5.315-0.549 31.434-26.475 56.472-57.909 55.926s-56.472-26.475-55.926-57.909l0.308-16.334 2.11-93 0.246-11.744-360.933 330.867c-23.176 21.246-59.187 19.678-80.428-3.495-20.849-22.748-19.728-57.854 2.233-79.234l1.267-1.193 355.396-325.787-134.861-2.783c-30.889-0.638-55.524-25.77-55.748-56.469l0.013-1.62c0.638-30.889 25.77-55.524 56.469-55.748z" />
                    </svg>
                  </a>
                  <a
                    href="/app_download/android"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="APP"
                    target="_self"
                  >
                    <span>APP</span>
                  </a>
                  <button className="footer-link-item">Live Support</button>
                </div>
              </div>
              <div className="footer-link-wrap">
                <p className="f-t">About us</p>
                <div className="links">
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="News"
                    target="_blank"
                  >
                    <span>News</span>
                    <svg
                      className="hashbet"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M365.386 83.556c31.44 0 56.926 25.486 56.926 56.926 0 30.897-24.617 56.046-55.307 56.904l-1.62 0.022h-170.777v629.186h629.186v-170.777c0-30.897 24.617-56.046 55.307-56.904l1.62-0.022c30.897 0 56.045 24.617 56.904 55.307l0.022 1.62v179.768c0 57.258-45.887 103.796-102.894 104.848l-1.968 0.017h-647.16c-57.258 0-103.796-45.887-104.848-102.894l-0.017-1.968v-647.16c0-57.258 45.887-103.796 102.894-104.848l1.968-0.017h179.768zM611.108 84.556l1.62 0.013 227.481 4.701c57.907 1.009 104.028 48.774 103.019 106.677l-1.176 62.495-1.198 54.931-1.442 62.539-0.437 21.864c-0.034 1.776-0.066 3.547-0.094 5.315-0.549 31.434-26.475 56.472-57.909 55.926s-56.472-26.475-55.926-57.909l0.308-16.334 2.11-93 0.246-11.744-360.933 330.867c-23.176 21.246-59.187 19.678-80.428-3.495-20.849-22.748-19.728-57.854 2.233-79.234l1.267-1.193 355.396-325.787-134.861-2.783c-30.889-0.638-55.524-25.77-55.748-56.469l0.013-1.62c0.638-30.889 25.77-55.524 56.469-55.748z" />
                    </svg>
                  </a>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Work with us"
                    target="_blank"
                  >
                    <span>Work with us</span>
                    <svg
                      className="hashbet"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M365.386 83.556c31.44 0 56.926 25.486 56.926 56.926 0 30.897-24.617 56.046-55.307 56.904l-1.62 0.022h-170.777v629.186h629.186v-170.777c0-30.897 24.617-56.046 55.307-56.904l1.62-0.022c30.897 0 56.045 24.617 56.904 55.307l0.022 1.62v179.768c0 57.258-45.887 103.796-102.894 104.848l-1.968 0.017h-647.16c-57.258 0-103.796-45.887-104.848-102.894l-0.017-1.968v-647.16c0-57.258 45.887-103.796 102.894-104.848l1.968-0.017h179.768zM611.108 84.556l1.62 0.013 227.481 4.701c57.907 1.009 104.028 48.774 103.019 106.677l-1.176 62.495-1.198 54.931-1.442 62.539-0.437 21.864c-0.034 1.776-0.066 3.547-0.094 5.315-0.549 31.434-26.475 56.472-57.909 55.926s-56.472-26.475-55.926-57.909l0.308-16.334 2.11-93 0.246-11.744-360.933 330.867c-23.176 21.246-59.187 19.678-80.428-3.495-20.849-22.748-19.728-57.854 2.233-79.234l1.267-1.193 355.396-325.787-134.861-2.783c-30.889-0.638-55.524-25.77-55.748-56.469l0.013-1.62c0.638-30.889 25.77-55.524 56.469-55.748z" />
                    </svg>
                  </a>
                  <button className="footer-link-item">
                    Business Contacts
                  </button>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Help Desk"
                    target="_blank"
                  >
                    <span>Help Desk</span>
                    <svg
                      className="hashbet"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M365.386 83.556c31.44 0 56.926 25.486 56.926 56.926 0 30.897-24.617 56.046-55.307 56.904l-1.62 0.022h-170.777v629.186h629.186v-170.777c0-30.897 24.617-56.046 55.307-56.904l1.62-0.022c30.897 0 56.045 24.617 56.904 55.307l0.022 1.62v179.768c0 57.258-45.887 103.796-102.894 104.848l-1.968 0.017h-647.16c-57.258 0-103.796-45.887-104.848-102.894l-0.017-1.968v-647.16c0-57.258 45.887-103.796 102.894-104.848l1.968-0.017h179.768zM611.108 84.556l1.62 0.013 227.481 4.701c57.907 1.009 104.028 48.774 103.019 106.677l-1.176 62.495-1.198 54.931-1.442 62.539-0.437 21.864c-0.034 1.776-0.066 3.547-0.094 5.315-0.549 31.434-26.475 56.472-57.909 55.926s-56.472-26.475-55.926-57.909l0.308-16.334 2.11-93 0.246-11.744-360.933 330.867c-23.176 21.246-59.187 19.678-80.428-3.495-20.849-22.748-19.728-57.854 2.233-79.234l1.267-1.193 355.396-325.787-134.861-2.783c-30.889-0.638-55.524-25.77-55.748-56.469l0.013-1.62c0.638-30.889 25.77-55.524 56.469-55.748z" />
                    </svg>
                  </a>
                  <button className="footer-link-item">
                    Verify Representative
                  </button>
                  <a
                    href="#"
                    keep-scroll-position="true"
                    className="footer-link-item"
                    title="Hashbet"
                    target="_blank"
                  >
                    <span>Hashbet</span>
                    <svg
                      className="hashbet"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <path d="M365.386 83.556c31.44 0 56.926 25.486 56.926 56.926 0 30.897-24.617 56.046-55.307 56.904l-1.62 0.022h-170.777v629.186h629.186v-170.777c0-30.897 24.617-56.046 55.307-56.904l1.62-0.022c30.897 0 56.045 24.617 56.904 55.307l0.022 1.62v179.768c0 57.258-45.887 103.796-102.894 104.848l-1.968 0.017h-647.16c-57.258 0-103.796-45.887-104.848-102.894l-0.017-1.968v-647.16c0-57.258 45.887-103.796 102.894-104.848l1.968-0.017h179.768zM611.108 84.556l1.62 0.013 227.481 4.701c57.907 1.009 104.028 48.774 103.019 106.677l-1.176 62.495-1.198 54.931-1.442 62.539-0.437 21.864c-0.034 1.776-0.066 3.547-0.094 5.315-0.549 31.434-26.475 56.472-57.909 55.926s-56.472-26.475-55.926-57.909l0.308-16.334 2.11-93 0.246-11.744-360.933 330.867c-23.176 21.246-59.187 19.678-80.428-3.495-20.849-22.748-19.728-57.854 2.233-79.234l1.267-1.193 355.396-325.787-134.861-2.783c-30.889-0.638-55.524-25.77-55.748-56.469l0.013-1.62c0.638-30.889 25.77-55.524 56.469-55.748z" />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="footer-link-wrap">
                <p className="f-t">Join our Community</p>
                <div className="social-links">
                  <a
                    className="telegram"
                    href="#"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/share_7.f3cb39a2.png" alt="telegram" />
                  </a>
                  <a
                    className="github"
                    href="#"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/share_5.2a1f1696.png" alt="github" />
                  </a>
                  <a
                    className="twitter"
                    href="#"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/share_8.ae8effba.png" alt="twitter" />
                  </a>
                  <a
                    className="facebook"
                    href="#"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/share_3.21374ebb.png" alt="facebook" />
                  </a>
                  <a
                    className="discord"
                    href="#"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/share_2.45a347d3.png" alt="discord" />
                  </a>
                  <a
                    className="bitcoin"
                    href="#"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/share_1.522ec023.png" alt="bitcoin" />
                  </a>
                  <a
                    className="instagram"
                    href="#"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/share_6.b1025447.png" alt="instagram" />
                  </a>
                </div>
              </div>
            </div> 
            <div className="fdn9ps2 footer-coins-wrap">
              <p className="coins-t">Accepted Payment Method</p>
              <div className="coins-list">
                <img src="/icon/paybkash.png" alt="Icon" />
                <img src="/icon/pay16.png" alt="Icon" />
                <img src="/icon/pay33.png" alt="Icon" />
                <img src="/icon/pay34.png" alt="Icon" />
                <img src="/icon/pay45.png" alt="Icon" />
                <img src="/icon/pay47.png" alt="Icon" />
                <img src="/icon/pay59.png" alt="Icon" />
                <img src="/icon/pay60.png" alt="Icon" />
                <img src="/icon/pay91.png" alt="Icon" />
                <img src="/icon/pay61.png" alt="Icon" className="tap" />
              </div>
            </div>
            <div className="f19tyczm footer-gaming-wrap">
              <p className="tit">Sponsorship and Gaming Responsibilities</p>
              <div className="partner-list-wrap">
                <div className="footer-wrap-left">
                  <div className="sigma ">
                    <a
                      href="https://sigmaawards.org/"
                      keep-scroll-position="true"
                      rel="noopener noreferrer"
                      target="_blank"
                      className=""
                    >
                      <img alt="sigma" src="/assets/sigma_w.e8608c37.png" />
                    </a>
                  </div>
                  <div className="verify">
                    <a
                      href="https://cryptogambling.org"
                      keep-scroll-position="true"
                      rel="noopener noreferrer"
                      target="_blank"
                      className=""
                    >
                      <img alt="verify" src="/assets/verify_w.a13100a8.png" />
                    </a>
                  </div>
                  <div className="respon">
                    <a
                      href="https://www.responsiblegambling.org/"
                      keep-scroll-position="true"
                      rel="noopener noreferrer"
                      target="_blank"
                      className=""
                    >
                      <img alt="respon" src="/assets/respon_w.38884bf7.png" />
                    </a>
                  </div>
                  <div className="eighteen">
                    <a
                      href="@"
                      keep-scroll-position="true"
                      rel="noopener noreferrer"
                      className=""
                    >
                      <img
                        alt="eighteen"
                        src="/assets/eighteen_w.6097ab4b.png"
                      />
                    </a>
                  </div>
                </div>
                <div className="footer-wrap-right">
                  <a href="#">Help</a>
                  <a href="/banking">Banking</a>
                  <a href="/termsConditions">Terms & Conditions</a>
                  <a href="/cookiePolicy">Cookie Policy</a>
                  <a href="/complaints">Complaints Procedure</a>
                  <a href="/contactUs">Contact Us</a>
                  <a href="/privacyPolicy">Privacy Policy</a>
                  <a href="/fairPayouts">Fair Payouts</a>
                  <a href="/gamesList">Games List</a>
                </div>
              </div>
            </div>
            <div className="fzoz0z4 footer-text-wrap">
              <div className="left-text">
                <img
                  alt="logo"
                  className="logo light-mode"
                  src="/assets/media/logo/logo.svg"
                />
                <img
                  alt="logo"
                  className="logo dark-mode"
                  src="/assets/media/logo/logo.svg"
                />
                <p className="text-color">
                  A multi-award winning crypto casino. With a player-centric
                  approach, <span /> is able to satisfy millions of gamblers
                  across the globe. <span /> has its priority set on its
                  community, ensuring an everlasting and endlessly entertaining
                  gambling experience.
                </p>
              </div>
              <div className="right-text">
                <div className="licensed-t">
                  <a
                    href="https://knoxxit2.sharepoint.com/:b:/s/CIL-Validations/ES3IKThD11JIp7Nt65rIIskBqDKJPUz4cK23-a6gV7UwFQ?e=geGYID"
                    target="_blank"
                    className="license"
                  >
                    <img alt="license" src="/assets/license_w.49183d91.png" />
                  </a>
                  <p className="text-color">
                    <span /> is operated by BlockDance B.V. (Commercial register
                    of Curaçao no.158182, Emancipatie Boulevard Dominico F.
                    "Don" Martina 31, Curaçao) under a sublicense CIL pursuant
                    to Master gaming License #5536/JAZ.
                  </p>
                </div>
                <div className="copyright-top">
                  <div className="copyright-info">
                    <p className="ttu text-color">©2024 ALL RIGHTS RESERVED</p>
                  </div>
                  <div className="to-top">
                    <svg className="s1ff97qc icon" />
                    <span>Top</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
